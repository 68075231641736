/* You can add global styles to this file, and also import other style files */
@import "./styles/fonts";
@import "./styles/variables";
@import "./styles/toastr";
@import "./styles/theme";
@import "./styles/other-screen";
@import "./styles/tablet";
@import "./styles/mobile";

/* Formatter =================================================== */
#toast-container > div {
  opacity: 1;
}
body {
  margin: 0;
  padding: 0;
  color: $font-color;
  background: $bg-color;
  font-family: Montserrat;
  font-size: 0.875rem;
  text-decoration: none;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
}

button:hover,
button:focus {
  outline: 0;
}

.clear {
  clear: both;
}

h1 {
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 700;
  letter-spacing: 0.2em;
  color: #007953;
}

h2 {
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 700;
  letter-spacing: 0.2em;
  color: #007953;
}

h3 {
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 700;
  letter-spacing: 0.2em;
  color: #007953;
}

h4 {
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 600;
  letter-spacing: 0.2em;
  color: #007953;
}

h5 {
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 600;
  letter-spacing: 0.2em;
  color: #007953;
}

h6 {
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 600;
  letter-spacing: 0.2em;
  color: #007953;
}

p {
  font-family: "Lato";
  font-weight: 400;
  color: #6d7181;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin-bottom: 1.5rem;

  label {
    margin-bottom: 0.313rem;
    display: block;
  }

  input,
  .p-input-icon-left,
  .p-input-icon-right {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }
}

// ScrollBar ===================================================

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007953;
  background-clip: padding-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-top: 0;
  border-bottom: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
}

// Spinner ===================================================
.loading-overlay {
  position: fixed;
  z-index: 10001;
  background: rgba(249, 249, 249, 0.25);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wrap-spinner {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 10005;
    top: 43.5%;

    .lds {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      display: inline-block;
      text-align: center;
      position: relative;
    }

    .lds div {
      display: inline-block;
      position: absolute;
      left: 6px;
      width: 13px;
      background: #192b2f;
      animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds div:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }

    .lds div:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }

    .lds div:nth-child(3) {
      left: 45px;
      animation-delay: 0;
    }
  }
}

@keyframes lds {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

// CARDS =====================================================
.sk-card {
  padding: 1.5rem;
  position: relative;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 12px 24px rgba(243, 243, 243, 0.5);
  z-index: 0;

  &.no-relative {
    position: initial;
  }

  &.external-page {
    box-shadow: 0px 12px 32px #d4d4d4;
  }

  .ad-card-header {
    margin-bottom: 24px;
  }

  &.extra-bottom-left::after {
    content: "";
    position: absolute;
    background: url("./assets/images/extra-card-bottom-left.svg");
    width: 89px;
    height: 67px;
    z-index: -1;
    bottom: 0;
    left: 0;
  }

  &.extra-bottom-right::before {
    content: "";
    background: url("./assets/images/extra-card-bottom-right.svg");
    width: 68px;
    height: 37px;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
  }
}

// TEMA PAGINE ESTERNE =====================================================
#login,
#not-found {
  background-image: url(./assets/images/background_ball.png);
  // background: #f3f3f4;
  // background-image: url('./assets/images/bg-login3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;

  #header {
    padding: 40px;
    color: $white;
    // text-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
  }

  #main {
    padding: 40px;
    // background: $white;
    min-height: calc(100vh - 236px);
    // border-top-left-radius: $border-radius;
    // border-top-right-radius: $border-radius;
    // box-shadow: 0px -1px 25px rgba(102, 102, 102, 0.1);
  }

  #footer {
    padding: 40px;
    background: $white;
    border-top: 1px solid #e8e8e8;
  }
}

// TEMA BASE =====================================================

#body-main {
  background-image: url(./assets/images/background_ball.png);
  background-repeat: no-repeat;
  background-color: white;
  background-position: top center;
  background-size: cover;

  #main {
    min-height: calc(100vh - 246px);
    margin-top: 80px;

    .white {
      background: #fff;
    }
  }
}

// PERSONALIZZAZIONI TEMA =====================================================
.humanitas-sidebar {
  background: $sk-primary-color;

  .p-sidebar-header {
    .p-sidebar-close,
    .p-sidebar-icon {
      color: #fff;
    }
  }

  .p-sidebar-content {
    padding: 0;
    #sidebarMobile {
      .mobile-nav {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          button {
            border: 0;
            width: 100%;
            font-size: 16px;
            color: #ffffff;
            text-align: left;
            padding: 20px 20px;
            background: transparent;
            text-transform: initial;
            letter-spacing: 0.2em;
            border-bottom: 1px solid #00694b;
            display: flex;
            justify-content: space-between;

            &:hover {
              opacity: 0.8;
            }

            .icon-mobile-menu {
              width: 22px;
              height: 23px;
              margin-left: 10px;

              &.arrow-menu {
                background: url("./assets/images/freccia-menu.svg");
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            padding-left: 20px;

            li {
              cursor: pointer;
              border: 0;
              width: 100%;
              font-size: 16px;
              color: #ffffff;
              text-align: left;
              padding: 20px 20px;
              background: transparent;
              text-transform: initial;
              letter-spacing: 0.2em;
              border-bottom: 1px solid #00694b;
            }
          }
        }
      }
    }
  }
}
