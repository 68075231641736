/* Variabili Generali =================================================== */
$font-color: #141414;
$bg-color: #fafafa;
$white: #fff;
$greenSuccess: #13c55a;
$warning: #F6D61C;
$tertiary: #F25A50;
$bg-dialog: #F8F9FC;

/* Variabili Tema Humanitas ================================================ */
$border-radius: 12px;
$sk-black: #000;
$sk-blue-light: #1C5CCC;
$sk-blue: #0e2e66;
$sk-orange-light: #FFB08C;
$sk-orange: #f48756;
$sk-orange-dark: #e8713c;
$sk-orange-shadow: rgba(244, 135, 86, 0.16);
$sk-yellow: #ffe54d;
$sk-aquamarine: #9de5d0;
$sk-tertiary-pink-light: #FFEDF1;
$sk-tertiary-petrol: #0EB4A1;
$sk-purple: #8054BF;
$sk-grey-neutral-20: #F1F5FA;
$sk-grey-neutral-30: #E9EFF7;
$sk-grey-neutral-40: #CED6E2;
$sk-grey-neutral-50: #98A2B7;
$sk-grey-neutral-60: #626F87;
$sk-grey-neutral-70: #434E64;
$sk-primary-color: #007850;

/* Variabili Pagine Esterne ============================================= */
$bg-main: #f1f5fa;
$bg-main-nav: #f8f9fc;