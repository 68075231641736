/* Poppins Font =============================================================== */
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Thin.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ThinItalic.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraLight.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Light.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-LightItalic.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Italic.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-SemiBold.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Bold.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-BoldItalic.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraBold.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Black.ttf') format("truetype");
  font-weight: 800;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-BlackItalic.ttf') format("truetype");
  font-weight: 800;
  font-display: fallback;
  font-style: italic;
}


/* Montserrat Font =============================================================== */
@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Thin.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-ThinItalic.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-ExtraLight.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Light.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-LightItalic.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Regular.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Italic.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Medium.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-MediumItalic.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Bold.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-BoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-ExtraBold.ttf') format("truetype");
  font-weight: 800;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format("truetype");
  font-weight: 800;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-Black.ttf') format("truetype");
  font-weight: 900;
  font-display: fallback;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/montserrat/Montserrat-BlackItalic.ttf') format("truetype");
  font-weight: 900;
  font-display: fallback;
  font-style: italic;
}

/* Lato Font =============================================================== */
@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-Thin.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-ThinItalic.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-Light.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-LightItalic.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-Regular.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-Italic.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-Bold.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-BoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-Black.ttf') format("truetype");
  font-weight: 900;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/lato/Lato-BlackItalic.ttf') format("truetype");
  font-weight: 900;
  font-display: fallback;
  font-style: italic;
}