/*
  Breakpoint          Class     Dimensions
  X-Small             None      <576px
  Small               sm        ≥576px
  Medium              md        ≥768px
  Large               lg        ≥992px
  Extra large         xl        ≥1200px
  Extra extra large	  xxl       ≥1400px
*/


@media screen and (max-width: 1200px) {
  body {
    .test-responsive {
      background-color: yellow;
      padding: 10px;
    }

    .resizableDialog {
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }


    .main-nav {
      .nav {
        .user,
        .logout {
          display: none;
        }
        .mobile-button {
          display: inline-block !important;
        }
      }

    }

  }
}